<template>
<div v-if="userLoggedIn">
  <sidebar />
  <dash-nav />
  
  <div id="main-content" class="mt-[80px] bg-light min-h-[calc(100vh-89px)]" :class="sidebarOpen ? 'ml-[344px] max-w-[calc(100%-345px)]' : 'ml-[45px] max-w-[calc(100%-44px)]'">
    <router-view />
  </div>

</div>
<div v-else class>
  <navbar />
  <router-view/>
</div>


</template>

<script>
import store from './store/';
import { computed } from 'vue';
import Sidebar from './components/dashboard/Sidebar.vue';
import DashNav from './components/dashboard/DashNav.vue';
import Navbar from './components/Navbar.vue';

export default {
  components:{
    Sidebar,
    DashNav,
    Navbar
  },
  setup () {
    return {
      userLoggedIn: computed(() => store.state.userLoggedIn),
      sidebarOpen: computed(() => store.state.sidebarOpen),
      }

  }
}
</script>

