<template>
  <div>
    <vee-form :validation-schema="schema" @submit="submitReport">
      <div class="gap-3 mt-5 sm:flex">

        <div class="mt-2 sm:flex-grow">
          <label for="firstName" class="block mb-1 text-sm text-grayMedium dark:text-gray-300">{{
            $t("makeReport.firstName")
          }}</label>
          <vee-field type="text" name="firstName"
            class=" border-2 border-grayLight focus-within:bg-light outline-1 outline-main/30 text-gray-800 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            :placeholder="$t('makeReport.firstName')" required />
          <ErrorMessage class="text-pink-800" name="firstName" />
        </div>
        <div class="mt-2 sm:flex-grow">
          <label for="lastName" class="block mb-1 text-sm text-grayMedium dark:text-gray-300">{{
            $t("makeReport.lastName")
          }}</label>
          <vee-field type="text" name="lastName" :placeholder="$t('makeReport.firstName')"
            class=" border-2 border-grayLight focus-within:bg-light outline-1 outline-main/30 text-gray-800 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            required />
          <ErrorMessage class="text-pink-800" name="lastName" />
        </div>
      </div>
      <div class="gap-3 sm:flex">
        <div class="mt-2 sm:flex-grow">
          <label for="email" class="block mb-1 text-sm text-grayMedium dark:text-gray-300">Email Address</label>
          <vee-field type="email" name="email" placeholder="email@example.com"
            class="border-2 border-grayLight focus-within:bg-light outline-1 outline-main/30 text-gray-800 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" />
          <ErrorMessage class="text-pink-800" name="email" />
        </div>
        <div class="mt-2 sm:flex-grow">
          <label for="mobile" class="block mb-1 text-sm text-grayMedium dark:text-gray-300">{{
            $t("makeReport.mobile")
          }}</label>
          <vee-field type="number" name="mobile" placeholder=""
            class=" border-2 border-grayLight focus-within:bg-light outline-1 outline-main/30 text-gray-800 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            required />
        </div>
      </div>
      <div class="gap-3 sm:flex">
        <div class="mt-2 sm:flex-grow">
          <label for="nationality" class="block mb-1 text-sm text-grayMedium dark:text-gray-300">{{
            $t("makeReport.nationality")
          }}</label>
          <vee-field as="select" v-model="nationality" @change="isThai = !isThai" name="nationality"
            class=" border-2 border-grayLight focus-within:bg-light outline-1 outline-main/30 text-grayMedium text-sm rounded-lg block w-full p-2.5 dark:bg-gray dark:border-gray-500 dark:placeholder-grayLight dark:text-white">
            <option value="Thai" class="text-grayMedium">{{ $t("makeReport.thai") }}</option>
            <option value="Foreign" class="text-grayMedium">{{ $t("makeReport.foreign") }}</option>
          </vee-field>
        </div>
        <div v-if="nationality == 'Thai'" class="mt-2 mb-14 sm:flex-grow">
          <label for="tdoc" class="block mb-1 text-sm text-grayMedium dark:text-gray-300">Thai ID Number</label>
          <vee-field :bails="false" type="text" name="tdoc"
            class=" border-2 border-grayLight focus-within:bg-light outline-1 outline-main/30 text-gray-800 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" />
          <ErrorMessage class="text-pink-800" name="tdoc" />
        </div>
        <div v-else class="mt-2 mb-14 sm:flex-grow">
          <div class="inline mt-2">
            <label for="country" class="block mb-1 text-sm text-grayMedium dark:text-gray-300">{{
              $t("makeReport.country")
            }}</label>
            <vee-field as="select" name="country"
              class=" border-2 border-grayLight focus-within:bg-light outline-1 outline-main/30 text-gray-800 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
              <option v-for="country in countryList" :key="country.value" :value="country.value" class="text-gray-600">
                {{ country.label }}</option>
            </vee-field>
            <ErrorMessage class="text-pink-800" name="country" />
          </div>
          <div class="inline mt-2">
            <label for="passport" class="block mb-1 text-sm text-grayMedium dark:text-gray-300">{{
              $t("makeReport.passport")
            }}</label>
            <vee-field type="text" name="passport"
              class=" border-2 border-grayLight focus-within:bg-light outline-1 outline-main/30 text-gray-800 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" />
            <ErrorMessage class="text-pink-800" name="passport" />
          </div>
          <div class="inline mt-2">
            <label for="dob" class="block mb-1 text-sm text-grayMedium dark:text-grayLight">{{
              $t("makeReport.DOB")
            }}</label>
            <vee-field type="date" name="dob"
              class=" border-2 border-grayLight focus-within:bg-light outline-1 outline-main/30 text-grayMedium text-sm rounded-lg block w-full p-2.5 dark:bg-gray dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" />
          </div>
        </div>
      </div>

      <!--  BUTTONs -->
      <div id="button-group" class="absolute bottom-0 right-0 w-full">
        <div class="flex justify-between">
          <div class="flex mt-2 ">
            <!-- This is where the "previous" button used to be -->
          </div>
          <div class="flex mt-2 text-right ">
            <next-button type="submit">Submit</next-button>

          </div>
        </div>
      </div>
    </vee-form>
  </div>
</template>

<script>
// from countries api
const countries = require("i18n-iso-countries");
// support English and Thai languages
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/th.json"));

import nextButton from '@/components/buttons/nextButton.vue'
import { computed, beforeUnmount } from 'vue';
import { useFormValues } from 'vee-validate';

// firebase imports
import { db, auth } from '@/firebase/config'
import { setDoc, addDoc, collection, getDoc, serverTimestamp, arrayUnion, doc, updateDoc } from 'firebase/firestore'

export default {
  emits: ['tenantUpdated'],

  name: 'tenantDetails',
  components: {
    nextButton
  },
  methods: {
    consoleClick(e) {
      console.error(e)
    },
    async submitReport(values) {
      if (this.isThai && values.tdoc.length != 13) {
        // not valid ID number
        return
      }
      if (!this.isThai) {
        if (values.dob == null || values.passport == null || values.country == null) {
          // not valid
          return
        }

      }
      const docs = this.isThai ? values.tdoc : values.firstName + values.lastName + values.dob + values.country
      const fullName = `${values.firstName} ${values.lastName}`
      const colRefTenants = collection(db, 'tenants')
      const docRef = doc(colRefTenants, docs)
      try {

        const tenantExists = await getDoc(docRef)


        // if tenant does not exsist, add them to the database
        if (!tenantExists.exists()) {
          await setDoc(docRef, {
            firstName: values.firstName,
            lastName: values.lastName,
            fullName: fullName,
            email: values.email,
            mobile: values.mobile ? values.mobile : null,
            nationality: values.nationality,
            passport: values.passport ? values.passport : null,
            country: values.country ? values.country : null,
            dob: values.dob ? values.dob : null,
            aliases: [],
            createdAt: serverTimestamp()
          })
        }
        else {
          // tenant does exist - add them to the database.
          const tenantData = tenantExists.data()
          const oldName = tenantData.fullName

          // check first and last name in database = fullName.         
          // update all other values
          await updateDoc(docRef, {
            firstName: values.firstName,
            lastName: values.lastName,
            fullName: fullName,
            email: values.email,
            mobile: values.mobile ? values.mobile : null,
            // the array in firebase is actually a set - will only add if it doesn't already exist
            aliases: arrayUnion(oldName),
            updatedAt: serverTimestamp()
          })

        }
      } catch (error) {
        console.error('updating tenant ', error);
        return;
      }
      // reset all fields
      // @todo
      // emit back to parent to go to next page. log docID
      this.$emit('tenantUpdated', { id: docs, fullName: fullName })

    }
  },
  computed: {
    fullName: {
      get() {
        return this.firstname + ' ' + this.lastName;
      }
    },

    isThai: {
      get() {
        return (this.nationality == 'Thai') ? true : false
      },
      set() {
        return (this.nationality == 'Thai') ? true : false
      }
    },



  },
  data() {
    return {
      nationality: 'Thai',

      schema: {
        firstName: 'required|min:3|max:100|latin_thai_only',
        lastName: 'required|min:3|max:100|latin_thai_only',
        email: 'required|min:3|max:100|email',
        mobile: 'numeric',
        nationality: 'required',
      },

    }
  },
  setup() {
    const countryList = computed(() => {
      const list = countries.getNames('en', { select: 'official' })
      // if list needed in Thai:
      //const list = countries.getNames('th', {select: 'official'})
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }))
    })
    return {
      countryList
    }
  }


}
</script>

<style>

</style>

