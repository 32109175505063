<template>
        <header :class="sidebarOpen ? 'left-[345px] w-[calc(100%-345px)]' : 'left-[45px] w-[calc(100%-45px)]'" class="fixed top-0 flex justify-between p-5 bg-white shadow z-100">
        <h1 class="text-2xl font-semibold text-gray">
          <label for="nav-toggle" class="font-sans text-2xl font-bold text-gray">
            <span><i class=" mdi mdi-menu"></i></span>
          </label>
          {{currentPath}}
        </h1>
        <!-- no search yet
        <div class="search-wrapper overflow-x-hidden border-2 border-grayLight rounded-full h-[50px] flex align-middle focus-within:bg-slate-100 focus-within:border-main">
          <i class="inline-block py-2 pl-5 text-xl text-grayLight mdi mdi-magnify"></i>
          <input class="h-full pl-2 border-none hover:outline-none hover:border-none focus:outline-none focus-within:bg-slate-100" type="search" placeholder="search here" />
        </div>
        -->
        <div class="flex items-right user-wrapper">
          <img
            referrerpolicy="origin"
            :src="imageURL"
            alt=""
            class="rounded-full max-h-14"
          />
          <div class="relative px-10 py-5 ml-1 mr-4 w-lg">
            <h4 class="absolute top-0 left-0 text-lg">{{displayName}}</h4>
            <small class="absolute left-0 text-sm tracking-tight uppercase top-5 text-gray" >{{ role }}</small>
          </div>
        </div>
      </header>
</template>
<script>
import store from '../../store/index'
import router from '../../router/index'
import { doc, getDoc } from 'firebase/firestore'
import { db, auth } from '../../firebase/config'
import { computed, onMounted, ref } from 'vue'
export default {
    created() {
    const locale = localStorage.getItem("locale");
    // user has picked a language preference
    if (locale) {
      this.$i18n.locale = locale;
    }
    // use the preference from browser settings
    else if(navigator.language) {
      this.$i18n.locale = navigator.language.substring(0,2);
    }
  },
  setup() {
    let displayName = auth.currentUser.displayName
    let role = ref('');
    let imageURL = auth.currentUser.photoURL
    onMounted(async () => {
      // const docRef = doc(db, "users", auth.currentUser.uid);
      // const docSnap = await getDoc(docRef).then((doc) => {
      //   if (doc.exists) {
      //     const userdoc = doc.data();
      //     role.value = userdoc.role;
      //   }
      // });


    })
    return {
      sidebarOpen: computed(() => store.state.sidebarOpen),
      currentPath: computed(() => router.currentRoute.value.name),
      displayName,
      imageURL,
      role
      //role
    }
  }
}
</script>