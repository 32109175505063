<template>
    <vee-form :validation-schema="schema" @submit="handleSubmit" class="flex flex-col mt-5 bg-grey-lighter">
        <div v-if="reg_show_alert" :class="reg_alert_style" class="p-5 mb-4 font-bold text-center text-white">
            {{ reg_alert_msg }}
        </div>
        <div class="flex flex-col justify-center">
            <div class="w-full p-6 text-gray-800 bg-white ">
                <h1 class="mb-8 text-xl font-semibold text-center text-main">{{ $t("loginForm.createAccount") }}</h1>
                <!-- vee-field is an input field by default -->
                <div>
                    <div class="flex flex-row space-x-3">
                        <a href="#" @click="oauthLogin('FB')"
                            class="relative px-10 py-8 border-2 rounded-lg shadow-sm border-grayLight hover:bg-blue-100"><span>
                                <facebook-icon class="absolute cursor-pointer top-2 left-3 text-main" :size="50" />
                            </span><span class="text-center "></span></a>
                        <a href="#" @click="oauthLogin('Google')"
                            class="relative px-10 py-8 border-2 rounded-lg shadow-sm border-grayLight hover:bg-blue-100"><span><img
                                    src="../assets/google.png" class="absolute cursor-pointer top-4 left-5"
                                    width="30" /></span><span class="text-center "></span></a>

                    </div>
                    <div class="relative h-5 my-10 text-center border-b border-grayLight">
                        <span class="absolute px-4 bg-white top-1 left-48">{{ $t("loginForm.or") }}</span>
                    </div>
                    <label for="firstName" class="text-gray">{{ $t("loginForm.firstName") }}</label>
                    <vee-field type="text"
                        class="shadow-sm block mb-3 w-full text-sm text-gray-800 bg-light focus-within:bg-white border-2 rounded  border-grayLight outline-1 outline-main/30 p-2.5"
                        name="firstName" v-model="firstName" placeholder="" />
                    <ErrorMessage class="text-pink-600" name="firstName" />
                </div>
                <div>
                    <label class="text-gray" for="lastName">{{ $t("loginForm.lastName") }}</label>
                    <vee-field type="text"
                        class="shadow-sm block mb-3 w-full text-sm text-gray-800 border-2 rounded  border-grayLight focus-within:bg-light outline-1 outline-main/30 p-2.5"
                        name="lastName" v-model="lastName" placeholder="" />
                    <ErrorMessage class="text-pink-600" name="lastName" />
                </div>
                <div>
                    <label for="email" class="text-gray">{{ $t("loginForm.email") }}</label>
                    <vee-field type="text"
                        class="shadow-sm block mb-3 w-full text-sm text-gray-800 border-2 rounded  border-grayLight focus-within:bg-light outline-1 outline-main/30 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                        name="email" v-model="email" placeholder="" />
                    <ErrorMessage class="text-pink-600" name="email" />
                </div>
                <div>
                    <label for="password" class="text-gray">{{ $t("loginForm.password") }}</label>
                    <!-- :bails overrides the default 1 error message at a time rule -->
                    <vee-field name="password" :bails="false" v-slot="{ field, errors }">
                        <input type="password" v-model="password"
                            class="shadow-sm block mb-3 w-full text-sm text-gray-800 border-2 rounded  border-grayLight focus-within:bg-light outline-1 outline-main/30 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            placeholder="" v-bind="field" />
                        <div class="text-pink-600" v-for="error in errors" :key="error">
                            {{ error.message }}
                        </div>

                    </vee-field>
                </div>
                <div>
                    <label for="confirm_password" class="text-gray">{{ $t("loginForm.confirmPass") }}</label>
                    <ErrorMessage class="text-pink-600" name="password" />
                    <vee-field type="password"
                        class="shadow-sm block mb-3 w-full text-sm text-gray-800 border-2 rounded  border-grayLight focus-within:bg-light outline-1 outline-main/30 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                        name="confirm_password" v-model="confirm_password" placeholder="" />
                    <ErrorMessage class="text-pink-600" name="confirm_password" />
                    <label for="role" class="text-gray">{{ $t("loginForm.role") }}</label>
                    <vee-field as="select" name="role" v-model="role"
                        class="shadow-sm block mb-3 w-full text-sm text-gray-800 border-2 rounded-lg  border-grayLight focus-within:bg-light outline-1 outline-main/30 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                        <option value="landlord" class="text-gray">{{ $t("loginForm.landlord") }}</option>
                        <option value="agent" class="text-gray">{{ $t("loginForm.agent") }}</option>
                        <option value="agent" class="text-gray">{{ $t("loginForm.tenant") }}</option>
                        <option value="other" class="text-gray">{{ $t("loginForm.other") }}</option>
                    </vee-field>
                    <vee-field type="checkbox" v-model="agree" name="agree" id="agree" value="agree" />
                    <label for="agree" class="ml-3">By signing up, you agree to the
                        <router-link to="/terms" @click.prevent="toggleAuthModal"
                            class="text-gray-800 no-underline border-b border-gray" href="#">
                            Terms of Service
                        </router-link> and
                        <router-link to="/privacy" @click.prevent="toggleAuthModal"
                            class="text-gray-800 no-underline border-b border-gray" href="#">
                            Privacy Policy
                        </router-link></label>
                </div>
                <button type="submit"
                    class="w-full py-3 my-1 text-center text-white rounded bg-secondary hover:bg-secondaryDarker focus:outline-none">Create
                    Account</button>
                <div v-if="error" class="text-red-600">{{ error }}</div>

            </div>


        </div>
    </vee-form>
</template>
<script>
import FacebookIcon from 'vue-material-design-icons/Facebook.vue'
import GoogleIcon from 'vue-material-design-icons/Google.vue'
import { addDoc, serverTimestamp } from 'firebase/firestore'
import { usersCollection } from '@/firebase/config'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore, mapMutations } from 'vuex'
export default {
    name: 'AppRegisterForm',
    components: {
        FacebookIcon,
        GoogleIcon
    },
    methods: {
        ...mapMutations([
            'toggleAuthModal'
        ]),
    },
    setup() {
        // form data 
        const firstName = ref('')
        const lastName = ref('')
        const confirm_password = ref('')
        const email = ref('')
        const password = ref('')
        const role = ref('landlord')
        const error = ref(null)
        const agree = ref('')

        // submission data
        const reg_in_submission = ref(false)
        let reg_alert_style = ref('bg-sky-500')
        let reg_alert_msg = ref('Please wait! We are creating your account')
        let reg_show_alert = ref(false)

        const store = useStore()
        const router = useRouter()

        // define all of the validation rules that will be needed for this form
        const schema = {
            firstName: 'required|min:3|max:100|alpha_spaces',
            lastName: 'required|min:3|max:100|alpha_spaces',
            email: 'required|email|min:3|max:100',
            password: 'required|min:6|max:32',
            confirm_password: 'required|confirm:password',
            role: "required",

        }


        const oauthLogin = async (provider) => {
            try {
                await store.dispatch('gLogin', provider).then(() => {
                    console.log('success')
                    // not sure if this should be asynchronous
                }).catch((err) => {
                    reg_alert_msg.value = err.message
                    throw new Error(err)

                })
                // if successful, close modal and redirect to dashboard
                reg_alert_style.value = 'bg-emerald-500'
                reg_alert_msg.value = 'Success!'
                router.push({ name: 'Dashboard' });
                store.commit('toggleAuthModal')
                // reset
                reg_show_alert.value = false
                email.value = ''
                password.value = ''
                reg_in_submission.value = false

            }
            catch {
                reg_alert_style.value = 'bg-pink-800'
                reg_alert_msg.value = "Sorry, something went wrong."
                reg_in_submission.value = false
                reg_show_alert.value = true
            }

        }
        const handleSubmit = async () => {
            if (agree.value != 'agree') {
                reg_in_submission.value = false
                reg_alert_style.value = 'bg-pink-800'
                reg_alert_msg.value = 'You must agree to the terms to continue.'
                error.value = error.message
                return;
            }

            try {
                reg_in_submission.value = true
                reg_show_alert.value = true
                reg_alert_style.value = 'bg-sky-500'
                // access signup from actions
                await store.dispatch('signup', {
                    email: email.value,
                    password: password.value,
                    firstName: firstName.value,
                    lastName: lastName.value,
                    role: role.value,
                    agree: agree.value,
                    createdAt: serverTimestamp()
                })
            }
            catch (error) {
                // must be false now so that user can fix their errors
                reg_in_submission.value = false
                reg_alert_style.value = 'bg-pink-800'
                reg_alert_msg.value = 'There was a problem. Please try again later.'
                error.value = error.message
                return;
            }
            //alert_msg.value = 'Success! You have been logged in.'
            // set userLoggedIn to true
            reg_alert_style.value = 'bg-emerald-500'
            reg_alert_msg.value = 'Success! Your account has been created.'
            // redirect to dashboard and close modal
            router.push('/dashboard');
            store.commit('toggleAuthModal')


        }

        return {
            handleSubmit, email, password, error,
            confirm_password, role, firstName, lastName, agree,
            role, reg_in_submission, schema, reg_alert_style, reg_alert_msg, reg_show_alert, oauthLogin
        }
    }
}
</script>