<template>
 <input type="checkbox" id="nav-toggle" class="hidden"/>
    <div :class="sidebarOpen ? 'w-[345px]' : 'w-[45px]'" class="fixed top-0 left-0 z-10 h-full sidebar bg-main ">
      <span class="cursor-pointer collapse-icon hover:text-main/20" @click="toggleSidebar">
        <collapse-icon id="collapse" :class="sidebarOpen ? 'collapse-right' : 'collapse-left rotate-180'" class="absolute transition ease-in-out text-white/80" />
      </span>
      <div class="py-2 sidebar-brand h-90">
        <span class="inline-block pr-1 LOGO">
          <img v-if="!sidebarOpen" class="mt-12 ml-2" src="../../assets/SVG/img_logo.svg" width="30" alt="RentDee logo"
        />
          <img v-else src="../../assets/logo_white.svg" class="mt-8 ml-14" width="240" alt="RentDee logo"
        /></span>
        <h1 class="inline-block">RentDee</h1>
      </div>
      <div class="mt-6 sidebar-menu">
        <ul :class="sidebarOpen ? 'ml-20' : 'ml-0'">
          <li class="w-full text-light">
            <router-link to="/dashboard" class="relative block py-1 mb-4 rounded-l-full menu-item" href="">
              <home-icon :class="sidebarOpen ? 'left-3' : 'left-3'" class=" icon"></home-icon>
              <span :class="sidebarOpen ? 'flex ml-12' : 'invisible'" class="">{{ $t("dashNav.Dashboard")}}</span></router-link>
  
          </li>
          <li class="relative w-full mb-4 text-light">
            <a href="" class="block py-1 rounded-l-full menu-item"><span>
              <account-icon :class="sidebarOpen ? 'left-3' : 'left-3'" class="icon "></account-icon></span>
              <span :class="sidebarOpen ? 'flex ml-12' : 'invisible'" class="whitespace-nowrap">{{ $t("dashNav.myAccount")}}</span></a>
          </li>
          <li class="relative w-full mb-4 text-light">
            <a @click="toggleRepModal" class="block py-1 rounded-l-full cursor-pointer menu-item ">
              <file-icon :class="sidebarOpen ? 'left-3' : 'left-3'" class="icon "  />
              <span :class="sidebarOpen ? 'flex ml-12' : 'invisible'" class="">{{ $t("dashNav.Report")}}</span></a
            >
          </li>
          <li class="relative w-full mb-4 text-light">
            <a @click="toggleSearchModal" class="block py-1 rounded-l-full cursor-pointer menu-item ">
              <account-search :class="sidebarOpen ? 'left-3' : 'left-3'" class="icon "  />
              <span :class="sidebarOpen ? 'flex ml-12' : 'invisible'" class="">{{ $t("dashNav.search")}}</span></a
            >
          </li>

          <li class="relative w-full mb-4 text-light ">
            <router-link to="/help" class="block py-1 rounded-l-full menu-item">
              <span><help-icon :class="sidebarOpen ? 'left-3' : 'left-3'" class="icon"></help-icon></span>
              <span :class="sidebarOpen ? 'flex ml-12' : 'invisible'" class="">{{ $t("dashNav.help")}}</span></router-link
            >
          </li>

          <li class="relative w-full text-light">
            <a @click="handleClick" href="" class="block py-1 rounded-l-full menu-item "><span >
                <logout-icon :class="sidebarOpen ? 'left-3' : 'left-3'" class="icon"></logout-icon></span>
              <span :class="sidebarOpen ? 'flex ml-12' : 'invisible'" class="whitespace-nowrap">{{$t("dashNav.logout")}}</span></a
            >
          </li>
        </ul>
      </div>
    </div>

</template>
<script>
import HomeIcon from 'vue-material-design-icons/HomeModern.vue'
import AccountIcon from 'vue-material-design-icons/Account.vue'
import AccountSearch from 'vue-material-design-icons/AccountSearch.vue'
import FileIcon from 'vue-material-design-icons/FileChart.vue'
import HelpIcon from 'vue-material-design-icons/AccountQuestion.vue'
import LogoutIcon from 'vue-material-design-icons/Logout.vue'
import CollapseIcon from 'vue-material-design-icons/ArrowCollapseLeft.vue'
// vue imports 
import { useRouter } from 'vue-router'
import store from '../../store/index'
import { computed } from 'vue'
import { mapMutations } from 'vuex'
// custom imports


export default {
  name: 'Sidebar',
  components: {
    HomeIcon,
    AccountIcon,
    AccountSearch,
    FileIcon,
    HelpIcon,
    LogoutIcon,
    CollapseIcon
  },
  methods: {
        ...mapMutations([
      'toggleSidebar',
      'toggleRepModal',
      'toggleSearchModal'
    ]),


  },
  setup() {
    // const store = useStore()
    const router = useRouter()

    const handleClick = ()=> {
      store.dispatch('logout')
      router.push('/')
    }
    return { 
      user: computed(() => store.state.user ),
      authModalShow: computed(() => store.state.authModalShow),
      userLoggedIn: computed(() => store.state.userLoggedIn),
      sidebarOpen: computed(() => store.state.sidebarOpen),
      handleClick
    }
  
  },
}
</script>
<style scoped>
/* hide the logo text */
.sidebar-brand h1 {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.sidebar {
  transition: 0.6s ease;
}

.sidebar-menu a.active {
  @apply bg-white text-xl text-main;
}
.collapse-right {
  left: 310px;
  top: 10px;
}
.collapse-left {
  left: 10px;
  top: 10px;
}
.icon {
  @apply absolute bottom-1;
  color: inherit;
}

.menu-item:hover {
  @apply bg-secondary;
}
#collapse {
  transition: 0.2s linear;
}
.rotate-180 {
  transform: rotate(180deg);
}
</style>