<template>
  <div>
    <div class="mx-3 max-w-screen-2xl xl:mx-16">
      <nav class="md:flex md:justify-between md:items-center">
        <div class="flex items-center justify-between">

          <router-link to="/" class="bg-transparent " exact-active-class="no-active" @click="showMenu = false"><img
              width="170" src="../assets/logo_white.svg" />
          </router-link>
          <!-- Mobile menu button -->
          <div @click="showMenu = !showMenu" class="flex md:hidden">
            <button type="button"
              class="text-gray-800 hover:text-gray-400 hover:bg-blue-900 focus:outline-none focus:text-gray-400">
              <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                <path fill-rule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z">
                </path>
              </svg>
            </button>
          </div>
        </div>


        <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
        <ul @click="showMenu = false" :class="showMenu ? 'flex min-h-screen' : 'hidden items-center'"
          class="flex-col mt-8 space-y-6 mb-72 items-left md:mb-0 md:flex md:space-y-0 md:flex-row md:space-x-10 md:mt-3">
          <div class="px-2 pb-4 border-b border-blue-900 md:pb-0 md:pt-1 md:border-none">
            <span v-if="$i18n.locale === 'th'"><a class="cursor-pointer" @click="setLocale('en')"><img
                  src="../assets/SVG/gb_flag.svg" width="24" alt="English Language button" /></a></span>
            <span v-else><a class="cursor-pointer" @click="setLocale('th')"><img src="../assets/SVG/th_flag.svg"
                  width="24" alt="Thai Language button" /></a></span>
          </div>
          <!-- For logged-in users -->
          <div v-if="userLoggedIn">
            <span class="hidden text-sm font-normal text-gray-600 md:inline md:mr-3 ">Logged in as {{
              user.email
            }}</span>
            <router-link to="/dashboard"
              class="block px-3 text-sm font-bold text-gray-800 border-b-2 md:inline text-normal hover:text-secondary ">
              Manage
            </router-link>
            <li @click="handleClick"
              class="px-3 text-sm font-bold text-gray-800 border-b-2 cursor-pointer md:inline hover:text-secondary">
              {{ $t("dashNav.logout") }}
            </li>

          </div>
          <!-- for logged out users -->
          <div v-else class="flex flex-col md:flex-row">
            <li class="pb-6 border-b border-blue-900 md:pb-0 md:pt-1 md:border-none">
              <a class="px-2 text-white hover:text-grayMedium" href="#" @click="toggleAuthModal">
                {{ $t("login") }} </a>
            </li>
            <li class="py-6 border-b border-blue-900 md:pb-0 md:pt-1 md:border-none">
              <a @click.prevent="toggleAuthModal"
                class="px-3 py-2 mx-2 rounded-md cursor-pointer bg-secondary hover:text-grayMedium text-light"
                href="#">{{
  $t("signup")
                }} </a>
            </li>


          </div>
          <!-- old method
          <div v-if="!user">
          <li class="inline text-sm font-bold text-gray-800 hover:text-blue-400">
            <router-link :to="{ name: 'Signup' }">Sign Up</router-link>
          </li>
          
          <li class="inline ml-4 text-sm font-bold text-gray-800 hover:text-blue-400">
            <router-link :to="{ name: 'Login' }">Log in</router-link>
          </li>
          </div>-->

        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import store from '../store/index'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
export default {
  name: 'Navbar',
  data() {
    return {
      showMenu: false
    }
  },
  created() {
    // const locale = localStorage.getItem("locale");
    // // user has picked a language preference
    // if (locale) {
    //   this.$i18n.locale = locale;
    // }
    // use the preference from browser settings
    // if (navigator.language) {
    //   this.$i18n.locale = navigator.language;
    // }
    // else {
    //   this.$i18n.locale = 'th'
    // }
    // set the local to 'th'
    if (localStorage.getItem("locale")) {
      this.$i18n.locale = localStorage.getItem("locale")
    } else {
      this.$i18n.locale = 'th'
    }

  },
  methods: {
    ...mapMutations([
      'toggleAuthModal'
    ]),
    setLocale(Locale) {
      this.$i18n.locale = Locale;
      // save user's language preference in local storage
      localStorage.setItem("locale", Locale)
      // update data for toggle
      this.locale = this.$i18n.locale
    }
    // toggleAuthModal() {
    //   //calling the mutation
    //   this.$store.commit('toggleAuthModal');
    // }
  },
  setup() {
    // const store = useStore()
    const router = useRouter()

    const handleClick = () => {
      store.dispatch('logout')
      router.push('/')
    }
    return {
      handleClick,
      user: computed(() => store.state.user),
      authModalShow: computed(() => store.state.authModalShow),
      userLoggedIn: computed(() => store.state.userLoggedIn),
    }
  }
}
</script>
<style scoped>

</style>