<template> 
<dashboard />


  <auth-modal />
  <report-modal />
  <search-modal />
</template>

<script>
import Dashboard from './DashboardLayout.vue'
import AuthModal from './components/Auth.vue'
import ReportModal from './components/ReportModal.vue'
import SearchModal from './components/searchModal.vue'

export default {
  components: { 
    Dashboard,
    AuthModal,
    ReportModal,
    SearchModal
     },
  created() {
    this.$store.dispatch('init_login');
  }
}
</script>
