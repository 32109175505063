export default {
  "dashNav": {
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แดชบอร์ด"])},
    "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีพอร์ต"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่วยเหลือ"])},
    "landlord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าของบ้าน"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ออกจากระบบ"])},
    "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัญชีของฉัน"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหา"])},
    "tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เช่า"])}
  },
  "dashboard": {
    "findTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหารายชื่อผู้เช่า"])},
    "makeReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีพอร์ต"])},
    "reportTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายงานผู้เช่า"])},
    "reportsMade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายงานสำเร็จ"])},
    "searchTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังค้นหารายชื่อผู้ชื่อผู้เช่า"])},
    "searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าของบ้านค้นหา"])}
  },
  "header-cta-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาฟรี"])},
  "header-cta-button-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อผู้เช่า"])},
  "header-cta-first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปกป้องการสูญเสียรายได้ และความเสี่ยงต่อความเสียหายของทรัพย์สิน"])},
  "header-cta-second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เริ่มต้นค้นหารายชื่อผู้เช่า"])},
  "header-main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาฐานข้อมูลผู้เช่าที่มากที่สุดในประเทศไทย ฟรี"])},
  "header-top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อย่าเอาทรัพย์สินคุณไปเสี่ยง"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าสู่ระบบ"])},
  "main-cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายงานผู้เช่าแย่ๆกับเราเพื่อไม่ให้ไปทำแบบนี้กับคนอื่น"])},
  "main-cta-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีพอร์ต"])},
  "main-have-you-had": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เคยเจอปัญหาเหล่านี้มั้ย"])},
  "main-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายงานผู้เช่า"])},
  "main-last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เช่าแบบนี้มีแต่สร้างปัญหาให้ทุกคน"])},
  "main-ul-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผนังกำแพงพัง เสียหาย"])},
  "main-ul-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้างค่าเช่า หรือ จ่ายไม่ตรงเวลา"])},
  "main-ul-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทิ้งขยะ และ ทำลายบ้านเช่า ทรัพย์สิน"])},
  "main-ul-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อยู่ไม่ครบสัญญา"])},
  "makeReport": {
    "tenancyHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดเจ้าของบ้าน"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อจริง"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นามสกุล"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โทรศัพท์"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัญชาติ"])},
    "thai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไทย"])},
    "foreign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต่างชาติ"])},
    "ThaiID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai ID Number"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเทศ"])},
    "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พาสปอร์ตหมายเลข"])},
    "DOB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันเกิด"])},
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนเดือนที่ระบุในสัญญา"])},
    "brokeLease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เช่าละเมิดสัญญา? (ผู้เช่าย้ายออกก่อนครบกำหนด)"])},
    "rentOwed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เช่าค้างค่าเช่า?"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่ตั้ง/ ชื่ออาคาร"])},
    "damage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความเสียหายที่เกิดกับสถานที่เช่า"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัพโหลดภาพได้ถึง 4 ภาพ (ไม่เกิน 3 MB)"])},
    "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งไฟล์ที่นี่"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกไฟล์ภาพ"])},
    "submitHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งแบบฟอร์ม"])},
    "submitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาเพิ่มข้อมูลหรือรายละเอียดเพิ่มเติมในกรณีมีข้อพิพาท"])},
    "submitMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเพิ่มเติม"])},
    "successMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งรายงานสำเร็จ"])},
    "errorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากเกิดปัญหาในการส่งรายงาน กรุณาลองใหม่"])},
    "reportAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต้องการรายงานเพิ่ม?"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต่อไป"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช่"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ใช่"])}
  },
  "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sawadee, ", _interpolate(_named("name"))])},
  "reportTable": {
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบ"])},
    "entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานที่เช่า"])},
    "reportedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่รายงาน"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สเตตัส"])},
    "tenantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อผู้เช่า"])}
  },
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงทะเบียน"])},
  "loginForm": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าสู่ระบบ"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล์"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พาสเวิร์ด"])},
    "continueFB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with Facebook"])},
    "continueGG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with Google"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงทะเบียน"])},
    "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีบัญชี?"])},
    "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างบัญชี"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อจริง"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นามสกุล"])},
    "confirmPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยันรหัสผ่าน"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณคือ..."])},
    "landlord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าของบ้าน/คอนโด"])},
    "tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าของบ้าน/คอนโด"])},
    "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอเจนท์ / นายหน้า"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["other"])},
    "haveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])}
  }
}