import { createStore } from 'vuex'

//Firebase imports
import { auth, db } from '../firebase/config'
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    GoogleAuthProvider,
    getAuth,
    signInWithPopup, 
    signOut,
    FacebookAuthProvider

} from 'firebase/auth'
import { doc, setDoc } from 'firebase/firestore'

const store = createStore({
    state: {
        //The user state will initially be null. After login, this state will be updated
        user: null,
        // auth not ready at start because connection hasn't been made to firebase
        // check to see if the user is logged in on page refresh
        authModalShow: false,
        repModalShow: false,
        userLoggedIn: false,
        sidebarOpen: true,
        searchModalShow: false,
    },
    mutations: {
        //Mutation to update the user state
        //Takes in two arguments, the state and the payload. When we call this mutation, the payload will be user object from firebase auth
        //When the user logs out, we call the mutation and the payload will be null
        setUser(state, payload) {
            state.user = payload
            //Log out the user state

        },
        toggleAuthModal: (state) => {
            state.authModalShow = !state.authModalShow;
        },
        toggleRepModal: (state) => {
            state.repModalShow = !state.repModalShow;

        },
        toggleSidebar: (state) => {
            state.sidebarOpen = !state.sidebarOpen;
        },
        toggleAuth(state) {
            state.userLoggedIn = !state.userLoggedIn;
        },
        toggleSearchModal: (state) => {
            state.searchModalShow = !state.searchModalShow
        }
    },
    actions: {
        async signup({commit}, payload ){
            const response = await createUserWithEmailAndPassword(auth, payload.email, payload.password)
            if (response) {
                try {
                    await setDoc(doc(db, 'users', response.user.uid), {
                        firstName: payload.firstName,
                        lastName: payload.lastName,
                        role: payload.role,
                        email: payload.email,
                        agree: payload.agree,
                        created: payload.createdAt,
                        searchCount: 0,
                        reportCount: 0
                    });

                } catch(e) {
                    console.log(e)
                } 
                commit('setUser', response.user)
                commit('toggleAuth');
            }
        },
        init_login({ commit }) {
            const user = auth.currentUser;

            if(user) {
                commit('setUser', user)
                commit('toggleAuth');
            }
        },


        async login({ commit }, { email, password }){
            const response = await signInWithEmailAndPassword(auth, email, password)
            if (response) {
                commit('setUser', response.user)
                commit('toggleAuth')
            } else {
                throw new Error('login failed')
            }
        },
        async gLogin({ commit }, payload){
            let provider = ''
            if (payload == "Google") {
                provider = new GoogleAuthProvider();
            } else {
                provider = new FacebookAuthProvider();
            }
            // open popup to sign in 
            await signInWithPopup(getAuth(), provider)
            .then((result) => {
                commit('setUser', result.user);
                commit('toggleAuth');
            })
            .catch((err) => {
                console.log(err)
                throw new Error(err)
            })
            // return the user details
            // check to see if the user has registered - they will have a doc in 'users' table
                // if they have not registered then create a user.
                    // change the modal to ask whether they are a landlord, tenant or agent
                // else set user, close modal, redirect to dashboard.
            
        },

        async logout({ commit }){
            await signOut(auth)
            commit('toggleAuth')
            commit('setUser', null)
        }
    },
    getters: {
        //authModalShow: (state) => state.authModalShow,
    }

})

// export the store
export default store