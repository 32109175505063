<template>
  <div class="relative block">
    <div class="p-5 px-4 rounded vld-parent">
      <!-- Search form -->
      <form @submit.prevent="searchTenant" v-if="showForm" ref="formContainer" class="">
        <div>
          <label for="firstName" class="block my-2 text-sm font-medium text-gray dark:text-gray-300">{{
            $t("makeReport.firstName")
          }}</label>
          <input v-model="form.firstName" name="firstName"
            class="border border-grayLight text-gray-800 text-sm rounded-md block w-full p-2.5 focus:bg-mainLight focus-within:outline-none dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" />
        </div>
        <div>
          <label for="lastName" class="block my-2 text-sm font-medium text-gray dark:text-gray-300">{{
            $t("makeReport.lastName")
          }}</label>
          <input v-model="form.lastName" name="lastName"
            class="border border-grayLight text-gray-800 text-sm rounded-md block w-full p-2.5 focus:bg-mainLight focus-within:outline-none dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" />
        </div>
        <div>
          <label for="mobile" class="block my-2 text-sm font-medium text-gray dark:text-gray-300">{{
            $t("makeReport.mobile")
          }}</label>
          <input v-model="form.mobile" name="mobile"
            class="border border-grayLight text-gray-800 text-sm rounded-md block w-full p-2.5 focus:bg-mainLight focus-within:outline-none dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" />
        </div>
        <div class="mt-2 sm:flex-grow">
          <label for="nationality" class="block my-2 text-sm font-medium text-gray dark:text-gray-300">{{
            $t("makeReport.nationality")
          }}</label>
          <select name="nationality" v-model="form.nationality"
            class="border border-grayLight text-gray-800 text-sm rounded-md block w-full p-2.5 focus:bg-mainLight focus-within:outline-none dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
            <option value="Thai" class="text-gray-600">{{ $t("makeReport.thai") }}</option>
            <option value="foreign" class="text-gray-600">{{ $t("makeReport.foreign") }}</option>
          </select>
        </div>
        <div class="mt-2 sm:flex-grow" v-if="form.nationality === 'Thai'">
          <label for="tdoc" class="block mb-2 text-sm font-medium text-gray dark:text-gray-300">Thai ID Number</label>
          <input type="text" name="tdoc" v-model="form.tdoc"
            class="border border-grayLight text-gray-800 text-sm rounded-md block w-full p-2.5 focus:bg-mainLight focus-within:outline-none dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
        </div>
        <div class="mt-2 sm:flex-grow" v-else>
          <div class="inline mt-2">
            <label for="country" class="block mb-2 text-sm font-medium text-gray dark:text-gray-300">{{
              $t("makeReport.country")
            }}</label>
            <select name="country" v-model="form.country"
              class="border border-grayLight text-gray-800 text-sm rounded-md block w-full p-2.5 focus:bg-mainLight focus-within:outline-none dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
              <option v-for="country in countryList" :key="country.value" :value="country.value" class="text-gray-600">
                {{ country.label }}</option>
            </select>
          </div>
          <div class="inline mt-2">
            <label for="passport" class="block mb-2 text-sm font-medium text-gray dark:text-gray-300">{{
              $t("makeReport.passport")
            }}</label>
            <input type="text" name="passport" v-model="form.passport"
              class="border border-grayLight text-gray-800 text-sm rounded-md block w-full p-2.5 focus:bg-mainLight focus-within:outline-none dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
          </div>
          <div class="inline mt-2">
            <label for="dob" class="block mb-2 text-sm font-medium text-gray dark:text-gray-300">{{
              $t("makeReport.dob")
            }}</label>
            <input type="date" v-model="form.dob" name="dob"
              class="border border-grayLight text-gray-800 text-sm rounded-md block w-full p-2.5 focus:bg-mainLight focus-within:outline-none dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" />
          </div>
        </div>
        <button type="submit" class="w-full button bg-secondary hover:bg-secondaryDarker">{{
          $t("dashNav.search")
        }}</button>
      </form>
      <section id="searchResults" v-else class="h-48">
        <div>
          <animation-result :reportStatus="result" />
        </div>
        <div>
          <button @click="searchAgain" class="text-gray-900 bg-secondary button hover:bg-secondaryDarker">{{
            $t("dashNav.search")
          }}</button>
        </div>
      </section>


    </div>

  </div>

</template>
<script>
import { collection, query, where, getDocs, updateDoc, doc, increment } from 'firebase/firestore'
import { db, auth } from '../../firebase/config'
import { ref, computed } from 'vue'
import { useLoading } from 'vue-loading-overlay'
import AnimationResult from '../AnimatinResult.vue';
import { set, update } from 'lodash';
export default {
  name: 'search tenant',
  components: {
    AnimationResult,
  },
  setup() {
    let form = ref({
      firstName: '',
      lastName: '',
      nationality: 'Thai',
      tdoc: '',
      country: '',
      passport: '',
      dob: '',
      mobile: '',

    })
    // from countries api
    const countries = require("i18n-iso-countries");
    // support English and Thai languages
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/th.json"));

    const countryList = computed(() => {
      const list = countries.getNames('en', { select: 'official' })
      // if list needed in Thai:
      //const list = countries.getNames('th', {select: 'official'})
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }))
    })
    let result = {
      reportStatus: 'positive',
      resultClass: 'bg-white',
      resultText: '',
    }
    const showForm = ref(true)

    // ************* Loading *************
    const $loading = useLoading()
    const fullPage = ref(false)

    const onCancel = () => console.log('user cancelled the loader')


    function searchAgain() {
      // reset form class if searching again
      result.resultClass = 'bg-white'
      result.reportStatus = 'positive'
      form.value = {
        firstName: '',
        lastName: '',
        nationality: 'Thai',
        tdoc: '',
        country: '',
        passport: '',
        dob: '',
        mobile: ''
      }
      showForm.value = true
    }
    function searchValid(tenant) {
      if (tenant.tdoc != '') {
        // can search on tdoc only
        return true
      } else {
        // must have one of last name, dob, passport + mobile
        if (((tenant.lastName != '') || (tenant.dob != '' || tenant.passport != '')) && (tenant.mobile != '')) {
          return true
        } else {
          return false
        }
      }
    }
    function setResult(status) {
      if (status == 'positive') {
        result.resultClass = 'text-emerald-500'
      } else if (status == 'negative') {
        result.resultClass = 'text-pink-800'
      } else {
        result.resultClass = 'text-pink-800'
        result.resultText = 'Not enough information was provided. Please fill in more details.'
      }
      result.reportStatus = status
      // clear form
      form.value = {
        firstName: '',
        lastName: '',
        nationality: 'Thai',
        tdoc: '',
        country: '',
        passport: '',
        dob: '',
        mobile: ''
      }
      showForm.value = false
    }
    function getTenantID(tenantData) {
      if (tenantData.tdoc != '') {
        return tenantData.tdoc
      } else if (tenantData.firstName.length && tenantData.lastName.length && tenantData.dob.length && tenantData.country.length) {
        return (tenantData.firstName + tenantData.lastName + tenantData.dob + tenantData.country)
      } else {
        return false
      }

    }
    async function getStatusByID(id) {
      if (id !== null) {
        let reportColRef = collection(db, `tenants/${id}/reports/`);
        const reportDocs = await (getDocs(reportColRef));
        if (reportDocs.size == 0) {
          setResult('positive')
          return true
        }
        reportDocs.forEach((item) => {
          let items = item.data()
          const brokeLease = (items.brokeLease == 'true')
          const damage = (items.damage == 'true')
          const rentOwed = (items.rentOwed == 'true')
          if (brokeLease || damage || rentOwed) {
            // any of these three being true will mean negative tenant
            setResult('negative')
          } else {
            // no negative reports
            setResult('positive')
          }
        })

      }
    }
    async function getStatusByQuery(tenant) {
      //const reportsRef = collection(db, "reports")
      const tenantsRef = collection(db, "tenants")
      const queries = []
      if (tenant.lastName && tenant.mobile) {
        queries.push(query(tenantsRef, where("lastName", "==", tenant.lastName), where("mobile", "==", tenant.mobile)))
      }
      if (tenant.passport && tenant.mobile) {
        queries.push(query(tenantsRef, where("passport", "==", tenant.passport), where("mobile", "==", tenant.mobile)))
      }
      if (tenant.dob && tenant.mobile) {
        queries.push(query(tenantsRef, where("dob", "==", tenant.dob), where("mobile", "==", tenant.mobile)))
      }
      if (!queries.length) {
        return setResult('error')
      }

      let docFound = false;
      let i = 0;
      let tenantSnapshot
      while (!docFound && i < queries.length) {
        // change this query so that it returns the reports straight away!!
        tenantSnapshot = await (getDocs(queries[i]))
        if (tenantSnapshot.size == 0) {
          setResult('positive')
        }
        tenantSnapshot.forEach((doc) => {
          let qID = doc.id

          if (qID) {
            // if there is a tenant, get their report
            //items = qdata.reports

            let getReports = async (qID) => {

              let reportColRef = collection(db, `tenants/${qID}/reports/`);
              const reportDocs = await (getDocs(reportColRef)); // getting reports for that tenant
              reportDocs.forEach((item) => {
                let reports = item.data();
                const brokeLease = reports.brokeLease == 'true'
                const damage = reports.damage == 'true'
                const rentOwed = reports.rentOwed == 'true'
                if (brokeLease || damage || rentOwed) {
                  // any of these three being true will mean negative tenant
                  setResult('negative')
                  return
                } else {
                  // if this is the last item in queries, then set result as positive
                  if ((i + 1) >= queries.length) {
                    setResult('positive')
                    return
                  }
                }
              })

            }
            docFound = true
            getReports(qID)
          }

        })

        i++
      }

    }
    function searchTenant() {
      const tenant = form.value
      // check to see if required fields have been entered:
      if (!searchValid(tenant)) {
        setResult('error')
      } else {
        // search was valid. Check the tenant's reports.
        // update searchCount on User's profile
        const userRef = doc(db, "users", auth.currentUser.uid)
        try {
          updateDoc(userRef, {
            searchCount: increment(1)
          })
        } catch (e) {
          console.log('there was a problem', e)
        }

        // option 1: tenant is Thai and has a Thai ID no. OR foreign with all fields entered 
        const id = getTenantID(tenant)
        if (id) {
          getStatusByID(id)
          return

        } else {
          getStatusByQuery(tenant)

        }

        // option 2: tenant is foreign and we need to search on dob passport lastname mobile
      }
    }
    return {
      form,
      countryList,
      result,
      showForm,
      searchAgain,
      searchTenant

    }

  }
}
</script>