// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'
import { getFirestore, collection } from 'firebase/firestore'
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getStorage } from 'firebase/storage'
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAMvay0GObs6mVs55J57Pi_9roJEcQLGCo",
  authDomain: "dee-rent.firebaseapp.com",
  projectId: "dee-rent",
  storageBucket: "dee-rent.appspot.com",
  messagingSenderId: "116187534810",
  appId: "1:116187534810:web:6a3e74e69bf48afec9dce3",
  measurementId: "G-LEC38STZ88"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)

const usersCollection = collection(db, 'users')
const reportsCollection = collection(db,'reports')
const tenantsCollection = collection(db, 'tenants')
const analytics = getAnalytics();
export { auth, db, storage, usersCollection,  reportsCollection, tenantsCollection, analytics }
