<template>

  <!-- Report Modal -->
  <div class="fixed inset-0 z-10 overflow-x-hidden overflow-y-auto bg-black/60" id="modal"
    :class="{hidden: !searchModalShow }">
    <div class="flex items-stretch justify-start px-4 pt-4 pb-20 text-center align-middle sm:justify-center sm:p-0">

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden min-h-[600px] sm:align-top">&#8203;</span>

      <div
        class="w-full overflow-x-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-top sm:max-w-lg">
        <div class="flex justify-between p-4 shadow bg-main" id="search-header">
          <div>
            <h2 class="text-xl text-white">{{ title }}</h2>
          </div>
          <!-- Modal Close Button -->
          <div class="z-50 text-white cursor-pointer modal-close" @click.prevent="toggleSearchModal">
            <close-icon />
          </div>
        </div>
        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-10 py-4 text-left ">
          <!-- Modal Content -->
          <search-form class="relative min-h-[600px] overflow-y-hidden" />
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import { mapMutations, mapState } from 'vuex'
import CloseIcon from 'vue-material-design-icons/Close.vue'
import searchForm from '@/components/searchTenant/searchForm.vue'


export default {
  name: 'SearchModal',
  data() {
    return {
      title: "Find a Tenant"
    }
  },
  components: {
    CloseIcon,
    searchForm

  },
  computed: {
    ...mapState([
      'searchModalShow'
    ])
  },
  methods: {
    ...mapMutations([
      'toggleSearchModal'
    ])
  }
}
</script>