import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'


// load the view dynamically (when needed)
const Home = () => import('../views/Home.vue');
const Terms = () => import('../views/Terms.vue');
const Dashboard =() => import('@/views/Dashboard.vue');
const Help =() => import('@/views/Help.vue');
const Welcome =() => import('@/views/Welcome.vue');
const NotFound = () => import ('@/views/NotFound.vue');
const imageUpload = () => import ('@/views/imageUploading.vue');
const Navbar = () => import ('@/components/Navbar.vue');
const Privacy = () => import ('@/views/Privacy.vue');

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
      },
    ]
  }, 
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: Privacy,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // alias: '/manage',
    components: {
      default: Dashboard,
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/welcome',
    name: 'Welcome to RentDee',
    components: {
      default: Welcome,
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/help',
    name: 'Help',
    // alias: '/manage',
    components: {
      default: Help,
    },
    meta: {
      requiresAuth: true,
    }
  },


  {
    path: '/manage',
    redirect: { name: 'Dashboard' },
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass:'bg-white text-xl text-main'
  ,
  linkActiveClass: 'bg-white'
});

router.beforeEach((to, from, next) => {
  if(to.meta.requiresAuth && !store.state.userLoggedIn) {
    // need to login if not already logged in
    // open toggleAuthmodal
    store.commit('toggleAuthModal')
    next('Home')
    return
  } else {
    next();
  }

});


export default router
