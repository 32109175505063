<template>
  <vee-form @submit="formSubmit">
    <!-- LAST PAGE OF FORM -->
    <div id="pageHeader" class="my-3">
      <h3 class="mb-2 text-lg uppercase font text-main">{{ $t("makeReport.submitHeading") }}</h3>
    </div>
    <div class="mt-8">
      <p class="my-4 text-left text-md text-grayMedium">{{ $t("makeReport.submitText") }}</p>
      <vee-field as="textarea" v-model="description" name="description" rows="10"
        class="w-full p-2 mb-16 overflow-auto text-sm border-2 text-gray border-grayLight focus:border-main placeholder:text-gray-400 placeholder:text-sm"
        :placeholder="$t('makeReport.submitMsg')"></vee-field>
    </div>


    <!--  BUTTONs -->
    <div id="button-group" class="absolute w-full bottom-5 right-5">
      <div class="flex justify-between">
        <div class="flex mt-2 ">
          <!-- This is where the "previous" button used to be -->
        </div>
        <div class="flex mt-2 text-right ">
          <next-button type="submit">{{ $t("makeReport.next") }}</next-button>
        </div>
      </div>
    </div>
  </vee-form>
</template>

<script>
import nextButton from '@/components/buttons/nextButton.vue'
import TenantUpload from '@/components/TenantUpload.vue'



export default {
  emits: ['formSubmitted'],
  components: {
    nextButton,
    TenantUpload
  },
  data() {
    return {
      description: ''

    }
  },
  methods: {

    formSubmit(values) {

      const submitData = {
        description: values.description,
      };
      this.$emit('formSubmitted', submitData)
    }
  }
}
</script>

<style>

</style>