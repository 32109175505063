<template>
  <vee-form @submit="registerTenancy" :validation-schema="schema">
    <div class="mt-8">
      <div class="flex justify-between mt-2">
        <label for="tenancyLength" class="block w-2/3 mb-1 text-md text-grayMedium dark:text-gray-300">{{
    $t("makeReport.term")
}}</label>
        <vee-field type="number" v-model="tenancyLength" name="tenancyLength" min="1"
          class="block w-[100px] text-right text-sm text-gray-800 border-2 rounded-lg  border-grayLight focus-within:bg-light outline-1 outline-main/30 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" />
      </div>
    </div>

    <div class="flex justify-between w-full gap-2 mt-8">
      <div class="w-2/3">
        <p class="block mb-1 text-md text-grayMedium dark:text-gray-300">{{ $t("makeReport.brokeLease") }}</p>
      </div>
      <div class="mt-2">

        <vee-field type="radio" v-model="brokeLease" :class="brokeLease ? 'text-pink-500' : 'text-grayMedium'"
          name="brokeLease" value="true" class="w-3 h-3 sm:w-4 sm:h-4 form-radio checked:bg-pink-600" />
        <label for="true" :class="brokeLease ? 'text-pink-500' : 'text-grayMedium'"
          class="ml-1 text-sm font-semibold text-gray-500 sm:text-md">{{ $t("makeReport.yes") }}</label>

        <vee-field type="radio" class="w-3 h-3 ml-4 text-pink-500 sm:w-4 sm:h-4 form-radio checked:bg-emerald-600"
          id="false" name="brokeLease" value="false" />
        <label for="false" class="ml-1 text-sm font-semibold sm:text-md text-grayMedium">{{ $t("makeReport.no")
}}</label>

      </div>
    </div>
    <div class="flex justify-between w-full gap-2 mt-8">
      <div class="w-2/3">
        <p class="block mb-1 text-md text-grayMedium dark:text-gray-300">{{ $t("makeReport.rentOwed") }}</p>
      </div>

      <div class="mt-5">
        <vee-field type="radio" v-model="rentOwed" :class="rentOwed ? 'text-pink-500' : 'text-grayMedium'"
          name="rentOwed" value="true" class="w-3 h-3 sm:w-4 sm:h-4 form-radio checked:bg-pink-600" />
        <label for="true" :class="rentOwed ? 'text-pink-500' : 'text-grayMedium'"
          class="ml-1 text-sm font-semibold text-grayMedium sm:text-md">{{ $t("makeReport.yes") }}</label>
        <vee-field type="radio" class="w-3 h-3 ml-4 text-pink-500 sm:w-4 sm:h-4 form-radio checked:bg-emerald-600"
          id="false" name="rentOwed" v-model="rentOwed" />
        <label for="false" class="ml-1 text-sm font-semibold sm:text-md text-grayMedium">{{ $t("makeReport.no")
}}</label>
      </div>
    </div>
    <div v-if="rentOwed" class="mt-5 text-center">
      <div>
        <div class="flex justify-between w-full gap-2">
          <label for="rentValue" class="block w-2/3 mb-1 text-left text-md text-grayMedium dark:text-gray-300">Value of
            rent owing (THB):</label>
          <vee-field type="number" v-model="rentValue" name="rentValue"
            class="block w-[150px] text-sm text-gray-800 border-2 rounded-lg  border-grayLight focus-within:bg-light outline-1 outline-main/30 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="Rent in THB" />
        </div>
      </div>
    </div>
    <!--  BUTTONs -->
    <div id="button-group" class="absolute w-full bottom-5 right-5">
      <div class="flex justify-between">
        <div class="flex mt-2 ">
          <!-- This is where the "previous" button used to be -->
        </div>
        <div class="flex mt-2 text-right ">
          <next-button type="submit">Next</next-button>
        </div>
      </div>
    </div>
  </vee-form>
</template>

<script>
import nextButton from '@/components/buttons/nextButton.vue'
import { number } from 'yup/lib/locale'


export default {
  emits: ['tenancyUpdated'],
  components: {
    nextButton
  },
  data() {
    return {
      tenancyLength: 0,
      rentOwed: false,
      rentValue: 0,
      brokeLease: false,
      schema: {
        tenancyLength: 'required|numeric',
        rentOwed: '',
        brokeLease: '',
        rentValue: 'numeric|min:0',
      },
    }
  },
  methods: {
    registerTenancy(values) {

      const tenantData = {
        tenancyLength: values.tenancyLength,
        rentOwed: values.rentOwed,
        rentValue: values.rentValue ? values.rentValue : 0,
        brokeLease: values.brokeLease
      };
      this.$emit('tenancyUpdated', tenantData)
    }
  }
}
</script>

<style>

</style>