export default {
  "dashNav": {
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "landlord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["landlord"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant"])}
  },
  "dashboard": {
    "findTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Tenant"])},
    "makeReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Report"])},
    "reportTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a Tenant"])},
    "reportsMade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports Made"])},
    "searchTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search a Tenant"])},
    "searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant Searches"])}
  },
  "header-cta-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Search"])},
  "header-cta-button-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tenant name"])},
  "header-cta-first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Protect yourself from income loss and potential property damage."])},
  "header-cta-second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start a free search for a tenant"])},
  "header-main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Thailand's Largest Tenancy Database for FREE"])},
  "header-top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't put yourself at risk"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "main-cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a bad tenant here to help others avoid this tenant. "])},
  "main-cta-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
  "main-have-you-had": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you had:"])},
  "main-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a Bad Tenant"])},
  "main-last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad tenants cost everyone money."])},
  "main-ul-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holes in walls?"])},
  "main-ul-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpaid or late rent?"])},
  "main-ul-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubbish and damage throughout your property?"])},
  "main-ul-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A lease broken early?"])},
  "makeReport": {
    "tenancyHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenancy Details"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Phone Number"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nationality"])},
    "thai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
    "foreign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foreign"])},
    "ThaiID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai ID Number"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport Number"])},
    "DOB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length of Stay (in months)"])},
    "brokeLease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did this tenant break their lease? (They left before the end of the contract)"])},
    "rentOwed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was there rent outstanding after they left?"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location/building"])},
    "damage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was any damage done to your property?"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload up to 4 images (3MB or less)"])},
    "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop your files here"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Photos"])},
    "submitHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORM SUBMISSION"])},
    "submitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add any other details to clarify any of your responses. This will be useful if the tenant disputes the report."])},
    "submitMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add relevant details or information"])},
    "successMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The report has been submitted successfully."])},
    "errorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was a problem submitting the report. Please try again later."])},
    "reportAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make another report?"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])}
  },
  "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sawadee, ", _interpolate(_named("name"))])},
  "reportTable": {
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "entries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 entries"]), _normalize(["1 entry"]), _normalize([_interpolate(_named("n")), " entries"])])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property Location"])},
    "reportedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reported Date"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "tenantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant Name"])}
  },
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
  "loginForm": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "continueFB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with Facebook"])},
    "continueGG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with Google"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
    "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account?"])},
    "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an Account"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "confirmPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is your role?"])},
    "landlord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landlord"])},
    "tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant"])},
    "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent or Agency"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["other"])},
    "haveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])}
  }
}