<template>
  <vee-form @submit="registerProperty">
    <div class="flex justify-between mt-8 mb-4">
      <label for="property" class="block w-1/2 mb-1 text-left sm:w-2/3 text-md text-grayMedium dark:text-gray-300">{{
        $t("makeReport.location")
      }}:</label>
      <vee-field type="text" name="property" v-model="property"
        class="block w-1/2 text-sm text-gray-800 border-2 rounded-lg  border-grayLight focus-within:bg-light outline-1 outline-main/30 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
        :placeholder="$t('makeReport.location')" />
    </div>
    <div class="flex justify-between">
      <div>
        <p class="block w-2/3 mb-1 text-left text-md text-grayMedium dark:text-gray-300">{{ $t("makeReport.damage") }}
        </p>
      </div>
      <div class="w-1/2 mt-5 text-center">
        <vee-field type="radio" id="true" v-model="damage" :class="damage ? 'text-pink-500' : 'text-grayMedium'"
          name="damage" value="true" class="w-3 h-3 text-pink-500 sm:w-4 sm:h-4 form-radio checked:bg-pink-600" />
        <label for="true" :class="damage ? 'text-pink-500' : 'text-grayMedium'"
          class="ml-1 text-sm font-semibold sm:text-md text-grayMedium">{{ $t("makeReport.yes") }}</label>
        <vee-field type="radio" class="w-3 h-3 ml-4 text-pink-500 sm:w-4 sm:h-4 form-radio checked:bg-emerald-600"
          v-model="damage" name="damage" value="false" />
        <label for="false" class="ml-1 text-sm font-semibold sm:text-md text-grayMedium">{{
          $t("makeReport.no")
        }}</label>
      </div>
    </div>


    <div class="my-3">
      <tenant-upload @addPhoto="addPhoto($event)" />
    </div>


    <!--  BUTTONs -->
    <div id="button-group" class="absolute w-full bottom-5 right-5">
      <div class="flex justify-between">
        <div class="flex mt-2 ">
          <!-- This is where the "previous" button used to be -->
        </div>
        <div class="flex mt-2 text-right ">
          <next-button type="submit">{{ $t("makeReport.next") }}</next-button>
        </div>
      </div>
    </div>
  </vee-form>
</template>

<script>
import nextButton from '@/components/buttons/nextButton.vue'
import TenantUpload from '@/components/TenantUpload.vue'



export default {
  emits: ['propertyUpdated'],
  components: {
    nextButton,
    TenantUpload
  },
  data() {
    return {
      property: '',
      damage: false,
      photos: [],
      schema: {
        property: 'required|alphaSpaces',
        damage: null,
      },
    }
  },
  methods: {
    addPhoto(file) {
      this.photos.push(file)
    },
    registerProperty(values) {

      const propertyData = {
        property: values.property,
        damage: values.damage,
        photos: this.photos
      };
      this.$emit('propertyUpdated', propertyData)
    }
  }
}
</script>

<style>

</style>