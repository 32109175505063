import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// import firebase authentication
import { auth } from './firebase/config'
// import store 
import store from './store/index'
// lottie animations
import LottieAnimation from 'lottie-web-vue';

// validation library
import VeeValidatePlugin from './includes/validation';

import i18n from './i18n'

let app;

// check to see if user is authenticated by initialising firebase first
// only do this once
auth.onAuthStateChanged(()=> {
  if(!app) {
    app = createApp(App).use(i18n);
    app.use(store);
    app.use(router);
    app.use(VueLoading);
    app.use(VeeValidatePlugin);
    app.use(LottieAnimation);
    app.mount('#app')
  }

});

