<template>
  <button @click="onClick"
    class="relative sm:mx-auto w-[150px] text-white bg-secondary hover:bg-secondaryDarker focus:outline-none font-medium rounded-sm text-sm px-5 py-2.5 text-center dark:bg-sky-600 dark:hover:bg-sky-700 dark:focus:ring-sky-800">
    <slot>{{ $t("makeReport.next") }}</slot><span><next-icon class="absolute text-xl text-white top-2 right-2" /></span>
  </button>
</template>

<script>
import NextIcon from 'vue-material-design-icons/ArrowRightBold.vue'
export default {
  components: {
    NextIcon
  }

}
</script>

<style>

</style>