<template>
  <p>{{ $t("makeReport.successMsg") }}</p>
  <p>{{ $t("makeReport.reportAgain") }}</p>
  <button @click="newReport">{{ $t("dashboard.makeReport") }}</button>
</template>


<script>
export default {
  methods: {
    newReport() {
      this.$emit('newReport')
    }
  }
}
</script>

<style>

</style>