<template>
  <!-- Report Modal -->
  <div class="fixed inset-0 z-10 overflow-x-hidden overflow-y-auto bg-black/60" id="modal"
    :class="{ hidden: !repModalShow }">
    <div class="flex items-stretch justify-start px-4 pt-4 pb-20 text-center align-middle sm:justify-center sm:p-0">

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden min-h-[600px] sm:align-top">&#8203;</span>

      <div
        class="w-full overflow-x-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-top sm:max-w-lg">
        <div class="flex justify-between p-4 shadow bg-main" id="report-header">
          <div>
            <h2 class="text-xl text-white">{{ title.name }}</h2>
          </div>
          <!-- Modal Close Button -->
          <div class="z-50 text-white cursor-pointer modal-close" @click.prevent="closeModal">
            <close-icon />
          </div>
        </div>
        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-10 py-4 text-left relative min-h-[400px] overflow-y-hidden ">

          <!-- Modal Content -->
          <component :is="title.component" :key="componentKey" @tenant-updated="tenantUpdated"
            @tenancy-updated="tenancyUpdated" @property-updated="propertyUpdated" @form-submitted="submitForm"
            @new-report="newReport" />
          <!-- from before <tenant-form class="relative min-h-[600px] overflow-y-hidden"/> -->
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import { mapMutations, mapState } from 'vuex'
import CloseIcon from 'vue-material-design-icons/Close.vue'
import tenantDetails from '@/components/tenantForm/tenantDetails.vue'
import tenancyDetails from '@/components/tenantForm/tenancyDetails.vue'
import propertyDetails from '@/components/tenantForm/propertyDetails.vue'
import formSubmission from '@/components/tenantForm/formSubmission.vue'
import formSuccess from '@/components/tenantForm/formSuccess.vue'
import { onBeforeUnmount } from 'vue'

// firebase imports
import { db, auth } from '@/firebase/config'
import { addDoc, collection, serverTimestamp, arrayUnion, doc, updateDoc, setDoc, increment } from 'firebase/firestore'

export default {
  name: 'Rep',
  data() {
    return {
      componentKey: 0,
      page: 0,
      tenantData: {
        id: '',
        fullName: '',
        tenancyLength: 0,
        brokeLease: false,
        rentOwed: false,
        rentValue: 0,
        location: '',
        photos: [],
        damage: false
      },
      titles: [
        {
          name_en: 'Tenant Details',
          component: 'tenantDetails'
        },
        {
          name: 'Tenancy Details',
          component: 'tenancyDetails'
        },
        {
          name: 'Property Details',
          component: 'propertyDetails'
        },
        {
          name: 'Form Submission',
          component: 'formSubmission'
        },
        {
          name: 'Report Submitted',
          component: 'formSuccess'
        },
      ],
      titles_th: [
        {
          name: 'Tenant Details',
          component: 'tenantDetails'
        },
        {
          name: 'รายละเอียดเจ้าของบ้าน',
          component: 'tenancyDetails'
        },
        {
          name: 'Property Details',
          component: 'propertyDetails'
        },
        {
          name: 'ส่งแบบฟอร์ม',
          component: 'formSubmission'
        },
        {
          name: 'Report Submitted',
          component: 'formSuccess'
        },
      ]
    }
  },

  components: {
    CloseIcon,
    tenantDetails,
    tenancyDetails,
    propertyDetails,
    formSubmission,
    formSuccess


  },
  computed: {
    ...mapState([
      'repModalShow'
    ]),
    title() {
      let currentTitle = this.$i18n.locale == 'en' ? this.titles[this.page] : this.titles_th[this.page]
      return currentTitle
    }
  },
  methods: {
    ...mapMutations([
      'toggleRepModal'
    ]),
    closeModal() {
      // clear all inputs
      this.componentKey += 1
      // reset to first page
      this.page = 0
      //toggle modal
      this.$store.commit('toggleRepModal')
    },
    tenantUpdated(data) {
      // show next form
      this.page++
      this.tenantData.id = data.id
      this.tenantData.fullName = data.fullName

    },
    tenancyUpdated(data) {
      // show next form
      this.page++
      this.tenantData.tenancyLength = data.tenancyLength
      this.tenantData.brokeLease = data.brokeLease
      this.tenantData.rentOwed = data.rentOwed
      this.tenantData.rentValue = data.rentValue


    },
    propertyUpdated(data) {
      // show next form
      this.page++
      this.tenantData.damage = data.damage
      this.tenantData.photos = data.photos
      this.tenantData.property = data.property


    },
    async submitForm(data) {
      // update tenantData
      this.page++
      this.tenantData.description = data.description

      // submit tenantData to firestore
      const docid = this.tenantData.id
      const name = 'newdoc'
      const userRef = doc(db, "users", auth.currentUser.uid)
      const colRefTenants = collection(db, 'tenants')
      const colRefReports = collection(db, 'tenants', docid, 'reports')
      const docRef = doc(colRefTenants, this.tenantData.id)
      //const colRefReports = collection(db, 'reports')
      try {
        await addDoc(colRefReports, {
          brokeLease: this.tenantData.brokeLease,
          damage: this.tenantData.damage,
          description: this.tenantData.description,
          docID: this.tenantData.id,
          propertyLoc: this.tenantData.location,
          rentOwed: this.tenantData.rentOwed,
          rentValue: this.tenantData.rentValue,
          tenancyLength: this.tenantData.tenancyLength,
          // need to create the ref for photos and then update this value.
          photos: this.tenantData.photos,
          name: this.tenantData.fullName,
          // @todo still need to calculate the status
          createdAt: serverTimestamp(),
          uid: auth.currentUser.uid,
        })

        // add report ID to the tenant report array
        // await updateDoc(docRef, {
        //   reports: arrayUnion(reportRef.id)})

      }
      catch (e) {
        console.error('add report', e)
      }
      // update report count on user profile
      try {
        await updateDoc(userRef, {
          reportCount: increment(1)
        })
      } catch (e) {
        console.error('update report count', e)
      }

      // clear tenantData
      this.tenantData = {
        id: '',
        fullName: '',
        tenancyLength: 0,
        brokeLease: false,
        rentOwed: false,
        rentValue: 0,
        location: '',
        photos: [],
        damage: false
      }

      // show thank you 
    },
    newReport() {
      this.page = 0
    }
  },
}
</script>