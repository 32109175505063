import { Form as VeeForm, Field as VeeField, defineRule, ErrorMessage, configure
 } from 'vee-validate';
import { 
  required, min, max, alpha_spaces as alphaSpaces, email, confirmed, digits, numeric} from '@vee-validate/rules'

const latinThaiOnly = (str) => {
  return /^([\u0e00-\u0e7fA-Za-z\s]+)$/.test(str);
}

export default {
  install(app) {
    app.component('VeeForm', VeeForm);
    app.component('VeeField', VeeField);
    app.component('ErrorMessage', ErrorMessage)
    // any of the rules you want to use has to be registered here
    defineRule('required', required);
    defineRule('min', min);
    defineRule('max', max);
    defineRule('alpha_spaces', alphaSpaces);
    defineRule('email', email);
    defineRule('confirm', confirmed);
    defineRule('digits', digits);
    defineRule('numeric', numeric);
    defineRule('latin_thai_only', latinThaiOnly);

    configure({
      generateMessage: (ctx) => {
      // triggered when global validator returns false
      // ctx is the field that has the broken rule
      const messages = {
        required: `*required.`,
        min: 'Too short.',
        max: 'Too long.',
        alpha_spaces: 'an invalid character was used',
        email: 'Must be a valid email.',
        confirm:"The passwords don't match.",
        digits: "Not a valid identification number",
        numeric: "Must be a valid number.",
        latin_thai_only: "Must be Thai or Latin characters only",
      };

      const message = messages[ctx.rule.name]
      ? messages[ctx.rule.name]
      : `The field ${ctx.field} is invalid.`

    return message;
    },
    // these are all defaults, but you may wish to change them
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: false,
    validateOnModelUpdate: true
  });
},
};