<template>
  <div class="fixed inset-0 z-10 overflow-x-hidden overflow-y-auto bg-black/60" id="modal"
    :class="{hidden: !authModalShow }">
    <div class="flex items-stretch justify-start px-4 pt-4 pb-20 text-center align-middle sm:justify-center sm:p-0">

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden min-h-[600px] sm:align-top">&#8203;</span>

      <div
        class="w-full overflow-x-hidden text-center align-bottom transition-all transform rounded-lg shadow-xl sm:my-8 sm:align-top sm:max-w-lg">
        <div class="flex justify-between p-4 shadow bg-main " id="report-header">
          <div>
            <h2 class="font-sans text-xl font-semibold text-center text-light">Log in to your account or register</h2>
          </div>
          <!-- Modal Close Button -->
          <div class="z-50 cursor-pointer text-light modal-close" @click.prevent="toggleAuthModal">
            <close-icon />
          </div>
        </div>
        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-4 py-3 text-left bg-grayLight">
          <!-- Tabs -->
          <ul class="flex flex-wrap mb-4">
            <li class="flex-auto text-center">
              <a class="block px-4 py-3 transition rounded-l shadow" href="#" @click.prevent="tab='login'" :class="{ 'hover:text-white text-white bg-main border-main border-2': tab==='login',
              ' hover:border-main hover:text-main border-2 border-grayLight bg-white' : tab === 'register'}">Login</a>
            </li>
            <li class="flex-auto text-center">
              <a class="block px-4 py-3 transition rounded-r" href="#" @click.prevent="tab='register'"
                :class="{ 'hover:text-white text-white bg-main border-main border-2': tab==='register',
                ' border-2 border-grayLight bg-white hover:border-grayMedium hover:text-main' : tab === 'login'}">Register</a>
            </li>
          </ul>
          <!-- Modal Content -->
          <!-- Login Form -->
          <div v-if="tab==='login'">
            <app-login-form @register="signUp()"></app-login-form>
            <div class="my-3 text-center text-gray-800">
              Don't have an account?
              <a @click.prevent="tab='register'"
                class="no-underline border-b cursor-pointer text-main hover:text-secondaryDarker">Sign Up</a>
            </div>
          </div>
          <!-- Registration Form -->
          <div v-else>
            <app-register-form></app-register-form>
            <!-- already have account link to Login -->
            <div class="my-3 text-center text-gray-800">
              Already have an account?
              <a @click.prevent="tab='login'"
                class="no-underline border-b cursor-pointer text-main hover:text-secondaryDarker">Log In</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</template>
<script>
import { mapMutations, mapState } from 'vuex'
import CloseIcon from 'vue-material-design-icons/Close.vue'
import AppLoginForm from '@/components/LoginForm.vue'
import AppRegisterForm from '@/components/RegisterForm.vue'
export default {
  name: 'Auth',
  emits: ['signup'],
  data() {
    return {
      tab: 'login',

    }
  },
  components: {
    CloseIcon,
    AppLoginForm,
    AppRegisterForm

  },
  computed: {
    ...mapState([
      'authModalShow'
    ])
  },
  methods: {
    ...mapMutations([
      'toggleAuthModal'
    ]),
    signUp() {
      this.tab = 'register'
    }
  }
}
</script>