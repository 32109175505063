<template>
  <div v-if="reg_show_alert" :class="reg_alert_style" class="font-bold text-center text-white ">
    {{ reg_alert_msg }}
  </div>
  <vee-form @submit="handleLogin" :validation-schema="schema" class="flex flex-col bg-grey-500">
    <div class="">
      <div class="w-full px-6 py-8 text-gray-800 bg-white ">
        <h1 class="mb-4 text-xl font-semibold text-center text-main">{{ $t("loginForm.login") }}</h1>
        <div>
          <label for="name" class="text-gray">{{ $t("loginForm.email") }}</label>
          <vee-field type="text"
            class="block w-full mb-3 text-sm text-gray-800 placeholder-grayMedium border-2 rounded  border-grayLight shadow-lg focus-within:bg-white outline-1 bg-light outline-main/30 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-grayLight dark:text-white"
            name="email" v-model="email" placeholder='' />
          <ErrorMessage class="text-pink-600" name="email" />
        </div>
        <div>
          <label for="password" class="text-gray">{{ $t("loginForm.password") }}</label>
          <vee-field type="password"
            class="block w-full mb-3 text-sm text-gray-800 placeholder-grayMedium border-2 rounded  border-grayLight shadow-lg focus-within:bg-white outline-1 bg-light outline-main/30 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-grayLight dark:text-white"
            name="password" v-model="password" placeholder='' />
          <ErrorMessage class="text-pink-600" name="password" />
        </div>
        <button type="submit" :disabled="reg_in_submission"
          class="w-full py-3 my-1 text-center text-white rounded-full bg-secondary hover:bg-secondaryDarker focus:outline-none">{{
            $t("loginForm.login")
          }}</button>
        <div class="block">

          <a href="#" @click="oauthLogin('FB')"
            class="relative block w-full py-3 my-1 text-center border-2 rounded-full text-gray border-gray"><span>
              <facebook-icon class="absolute inline-block cursor-pointer bottom-3 left-14 text-main" :size="30" />
            </span><span class="text-center ">{{ $t("loginForm.continueFB") }}</span></a>
          <a href="#" @click="oauthLogin('Google')"
            class="relative block w-full py-3 my-1 text-center border-2 rounded-full text-gray border-gray"><span><img
                src="../assets/google.png" class="absolute inline-block cursor-pointer bottom-3 left-14"
                width="24" /></span><span class="text-center ">{{ $t("loginForm.continueGG") }}</span></a>
          <p class="text-center">{{ $t("loginForm.or") }}</p>
          <a href="#" @click="$emit('register')"
            class="block w-full py-3 my-1 text-center text-white rounded-full bg-main">{{ $t("loginForm.signup") }}</a>
        </div>

      </div>
    </div>

  </vee-form>
  <div class="mt-4 text-center">

  </div>
</template>
<script>
import FacebookIcon from 'vue-material-design-icons/Facebook.vue'
import GoogleIcon from 'vue-material-design-icons/Google.vue'
import { ref, defineEmits } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { FacebookAuthProvider, GoogleAuthProvider, getAuth, signInWithPopup } from '@firebase/auth'
export default {
  name: 'AppLoginForm',
  components: {
    FacebookIcon,
    GoogleIcon
  },
  emits: ['register'],


  setup() {

    const email = ref('')
    const password = ref('')
    let reg_in_submission = ref(false)
    let reg_show_alert = ref(false)
    let reg_alert_msg = ref('Please wait. We are logging you in.');
    let reg_alert_style = ref('bg-sky-500')

    const schema = {
      email: 'required|email|min:3|max:100',
      password: 'required|min:6|max:32',
    }

    const store = useStore()
    const router = useRouter()

    const oauthLogin = async (provider) => {
      console.log(provider);
      try {
        await store.dispatch('gLogin', provider).then(() => {
          console.log('success')
          // not sure if this should be asynchronous
        }).catch((err) => {
          reg_alert_msg.value = err.message
          throw new Error(err)

        })
        // if successful, close modal and redirect to dashboard
        reg_alert_style.value = 'bg-emerald-500'
        reg_alert_msg.value = 'Success!'
        router.push({ name: 'Dashboard' });
        store.commit('toggleAuthModal')
        // reset
        reg_show_alert.value = false
        email.value = ''
        password.value = ''
        reg_in_submission.value = false

      }
      catch {
        console.log(reg_alert_msg.value);
        reg_alert_style.value = 'bg-pink-800'
        reg_alert_msg.value = "Sorry, something went wrong."
        reg_in_submission.value = false
        reg_show_alert.value = true
      }

    }
    const handleLogin = async () => {
      // show loading message
      reg_show_alert.value = true
      reg_in_submission.value = true
      reg_alert_style.value = 'bg-sky-500'
      // submit request to firestore

      try {
        await store.dispatch('login', {
          email: email.value,
          password: password.value
        })
        // if successful, close modal and redirect to dashboard
        reg_alert_style.value = 'bg-emerald-500'
        reg_alert_msg = 'Success!'
        router.push('/dashboard');
        store.commit('toggleAuthModal')
        // reset
        reg_show_alert.value = false
        email.value = ''
        password.value = ''
        reg_in_submission.value = false

      }
      // if unsuccessful, re-enable the button and show message that an error occured
      catch {
        reg_alert_style.value = 'bg-pink-800'
        reg_alert_msg.value = 'Invalid login details.'
        reg_in_submission.value = false
        reg_show_alert.value = true
      }
    }


    return {
      schema,
      email,
      password,
      reg_in_submission,
      reg_alert_msg,
      reg_show_alert,
      reg_alert_style,
      handleLogin,
      oauthLogin
    }
  }
}
</script>