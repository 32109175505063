<template>
  <div class="relative flex flex-col w-full overflow-x-hidden bg-white rounded text-grayMedium">
    <div class="pt-4 pb-3 font-bold">
      <span :class="error" class="card-title">{{ $t("makeReport.upload") }}</span>
    </div>
    <div class="">
      <!-- Upload Dropbox -->
      <div
        class="w-full px-10 py-20 mb-10 text-center transition duration-500 border border-dashed rounded cursor-pointer text-grayMedium border-grayLight hover:text-white hover:bg-secondary hover:border-secondary hover:border-solid"
        :class="{ 'bg-secondary border-secondary border-solid': is_dragover }" @drag.prevent.stop=""
        @dragstart.prevent.stop="" @dragend.prevent.stop="is_dragover = false"
        @dragover.prevent.stop="is_dragover = true" @dragcenter.prevent.stop="is_dragover = true"
        @dragleave.prevent.stop="is_dragover = false" @drop.prevent.stop="upload($event)">
        <h5>Drop your files here</h5>
      </div>
      <div
        class="relative flex w-40 h-12 py-2 mt-4 border-2 rounded-md shadow-md border-grayLight hover:bg-secondaryDarker ">
        <upload-icon class="absolute left-2 top-2 text-secondary" />
        <label for="uploadInput"
          class="absolute left-0 z-10 pl-10 font-sans font-medium cursor-pointer top-2 text-grayMedium hover:text-white">Select
          Photos</label>
        <input type="file" class="absolute inset-0 left-0 z-0 hidden" id="uploadInput" multiple
          accept="image/png, image/jpeg" @change.prevent="upload($event)" />
      </div>

      <!-- Progess Bars -->
      <div class="relative mb-4" v-for="upload in uploads" :key="upload.name">
        <!-- File Name -->
        {{ upload.name }}
        <div class="text-sm font-bold " :class="upload.text_class">
          <div v-if="upload.current_progress < 100">
            <div v-if="upload.icon == 'spinner'"
              class="absolute top-0 left-0 w-4 h-4 ml-2 border-b-2 rounded-full spinner-border animate-spin border-sky-600">
            </div>
          </div>
          <div v-else class="absolute right-0 bottom-5">
            <div v-if="upload.icon == 'cross'" class="">
              <cross-icon class="text-red-500" />
            </div>
            <div v-else class="">
              <tick-icon class="text-secondary" />
            </div>
          </div>

        </div>
        <div class="flex h-4 overflow-hidden rounded bg-sky-500 last:mb-12">
          <!-- Inner Progress Bar -->
          <div class="transition-all progress-bar" :class="upload.variant"
            :style="{ width: upload.current_progress + '%' }"></div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import UploadIcon from 'vue-material-design-icons/CloudUpload.vue'
import LoadingIcon from 'vue-material-design-icons/Loading.vue'
import TickIcon from 'vue-material-design-icons/CheckCircleOutline.vue'
import CrossIcon from 'vue-material-design-icons/AlertCircle.vue'
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import { storage, auth } from '../firebase/config'
import { image } from '@vee-validate/rules'
import { onBeforeUnmount } from 'vue'
export default {
  name: 'TenantUpload',
  components: {
    UploadIcon,
    LoadingIcon,
    TickIcon,
    CrossIcon
  },
  data() {
    return {
      is_dragover: false,
      uploads: [],
      error: 'text-gray-900'
    }
  },
  beforeUnmount() {
  },
  emits: ['addPhoto'],
  methods: {
    upload($event) {
      // 
      let uploads = []
      let fileNames = []
      const uid = auth.currentUser.uid
      if (!uid) {
        return
      }
      this.is_dragover = false;
      // get the userID
      if (!uid) { return }
      // files are either from a drag and drop event or from an upload button event
      // must convert files object into an array. 
      let files = $event.dataTransfer ?
        [...$event.dataTransfer.files]
        : [...$event.target.files];

      if (files.length > 4) {
        this.error = 'text-red-500';
        // reset the input becuase the user will want to choose the 3 images
        files = null;
        return console.error('only 3 image allowed')
      }
      files.forEach((file) => {

        // @todo start loading icon

        const uploadIndex = this.uploads.push({
          task: {},
          current_progress: 0,
          name: file.name,
          variant: 'bg-sky-400',
          icon: 'spinner',
          text_class: '',
        }) - 1;

        // check valid file type is image
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
        if (!validImageTypes.includes(file.type)) {
          this.uploads[uploadIndex].variant = 'bg-red-400';
          this.uploads[uploadIndex].icon = 'cross';
          this.uploads[uploadIndex].text_class = 'text-red-400';
          this.uploads[uploadIndex].current_progress = 100;

          return
        }
        // reduce file size
        // extension of reduced image will be .png
        let name = file.name
        name = name.split('.')[0];
        name = name + '.png'
        fileNames.push(name)

        let resizedHeight;
        // child reference for image storage
        const imageRef = ref(storage, `images/${uid}/${name}`); //dee-rent.appspot.com/images/filename
        // trying to save the vue instance for a callback (emit)
        const self = this;
        // draw the image
        let reader = new FileReader();
        URL = reader.readAsDataURL(file)
        reader.onload = () => {
          let img = new Image();
          img.src = reader.result;
          img.onload = () => {
            let resizedWidth = img.width <= 512 ? img.width : 512;
            resizedHeight = resizedWidth / (img.width / img.height)
            let canvas = document.createElement("canvas");
            let ctx = canvas.getContext("2d");
            // create a resized canvas and draw the image
            canvas.width = resizedWidth;
            canvas.height = resizedHeight;
            document.body.appendChild(canvas);
            ctx.drawImage(img, 0, 0, resizedWidth, resizedHeight)
            // this doesn't work - image not created

            const photo = {
              display_name: auth.currentUser.displayName,
              original_name: name,
              url: '',
            };

            canvas.toBlob(async function (blob) {
              let metadata = {
                contentType: "image/png"
              };
              const task = uploadBytesResumable(imageRef, blob, metadata);
              task.on('state_changed', (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

              }, (error) => {
                self.uploads[uploadIndex].variant = 'bg-pink-800';
                self.uploads[uploadIndex].icon = 'cross';
                self.uploads[uploadIndex].text_class = 'text-pink-800';
                self.uploads[uploadIndex].current_progress = 100;
                console.error(error);
              }, async () => {
                photo.url = await getDownloadURL(task.snapshot.ref);
                // no longer need canvas
                document.body.removeChild(canvas);
                self.$emit('addPhoto', photo)

                self.uploads[uploadIndex].current_progress = 100;
                self.uploads[uploadIndex].variant = 'bg-emerald-400';
                self.uploads[uploadIndex].text_class = 'text-emerald-400';
                self.uploads[uploadIndex].icon = 'check'

              })

            }); // canvas.toBlob

          }// img.onload

        }// reader.onload
      }) // forEach

    } // upload($event)

  }
  // beforeUnmount() {
  //   this.uploads.forEach((upload) => {
  //     upload.task.cancel();
  //   })
  // },
};
</script>

<style scoped>

</style>