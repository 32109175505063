<template>
  <lottie-animation id="animation" ref="anim" :animationData="this.animData" :loop="false" :autoPlay="true" />
  <div v-if="status.reportStatus == 'positive'">

    <h2 class="text-xl font-semibold text-green-600">Good news!</h2>
    <p class="text-lg text-gray-600">We didn't find any negative reports for this tenant</p>
  </div>
  <div v-else-if="status.reportStatus =='negative'">
    <h2 class="text-xl font-semibold text-pink-800">Oh no!</h2>
    <p class="text-lg text-gray-600">We found at least one report that was negative.</p>
  </div>
  <div v-else>
    <h2 class="text-xl font-semibold text-pink-800">Error</h2>
    <p class="text-lg text-gray-600">More information is needed to perform the search.</p>
  </div>
</template>
<script>
import { LottieAnimation } from 'lottie-web-vue'
import { onMounted, ref, toRefs } from 'vue'
import CheckJSON from "../assets/check.json"
import CrossJSON from "../assets/cross.json"
export default {
  components: {
    LottieAnimation
  },
  props: [
    'reportStatus',
  ]
  ,
  setup(props) {
    let anim = ref()
    const result = toRefs(props, 'reportStatus')
    const status = result.reportStatus.value
    let animData = status.reportStatus == 'positive' ? CheckJSON : CrossJSON
    onMounted(() => {
      // the DOM element will be assigned to the ref after initila rendering
      anim.value.play
    })
    // animData = if success, check if not cr
    return {
      anim,
      CheckJSON,
      CrossJSON,
      animData,
      status
    }

  },

}
</script>
<style scoped>
#animation {
  max-width: 70%;
  margin: auto;
}
</style>